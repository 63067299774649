import React from 'react';

const IconLoader = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 100 100"
    id="logo"
    role="img"
  >
    <title>Loader Logo</title>
    <g>
      <g id="D" transform="translate(11.000000, 5.000000)">
        <path
          transform="translate(11.000000, 16.000000) scale(1.2 1.2)"
          fill="currentColor"
          d="M12 12C12 10.8954 12.8954 10 14 10H22C29.732 10 36 16.268 36 24C36 31.732 29.732 38 22 38H14C12.8954 38 12 37.1046 12 36V12ZM16 14V34H22C27.5228 34 32 29.5228 32 24C32 18.4772 27.5228 14 22 14H16Z"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
          L 11, 27
          L 11, 72
          L 50, 95
          L 89, 73
          L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
