import React from 'react';

const IconLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 84 96"
    id="logo"
    role="img"
  >
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
        <path
          transform="translate(12.000000, 16.000000) scale(1.2 1.2)"
          fill="currentColor"
          d="M12 12C12 10.8954 12.8954 10 14 10H22C29.732 10 36 16.268 36 24C36 31.732 29.732 38 22 38H14C12.8954 38 12 37.1046 12 36V12ZM16 14V34H22C27.5228 34 32 29.5228 32 24C32 18.4772 27.5228 14 22 14H16Z"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
